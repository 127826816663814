<template>
  <div
    class="game-body"
    v-if="loadedPage"
    :style="cssProps">
    <div class="game-desktop-background"></div>
    <div v-if="step != 'finished'" class="game-content-background"></div>
    <span
      v-if="isDevelopment"
      class="dev-navigation">
      DEV
      <router-link class="btn" :to="{ name: 'LoyaltyShopOnboarding' }">onboarding</router-link>
    </span>

    <div
      v-if="step == 'finished'"
      class="game-page-absolute">
      <div v-html="game.winner_text"></div>
      <div class="btn btn-activate"
        @click="ctaDoneRedirect()">
        {{ game.cta_done_button || transl.Games.back }}
      </div>
    </div>

    <div v-else class="game-page">
      <div
        v-if="step == 'tiebreaker'"
        class="tiebreaker-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.tiebreak_image" :src="game.tiebreak_image.url" alt="">
          <div
            v-if="game.map_image"
            class="map-icon-right"
            @click="mapModal = true"
            style="cursor: pointer;">
            <iconComponent
              class="game-info-icon"
              symbol="map"
              width="18px"/>
          </div>
        </div>

        <div class="tiebreaker-question">
          <div class="tiebreaker-text" v-html="game.tiebreak_text"></div>
          <Form
            :questions="[game.tiebreak_question]"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="game.tiebreak_button_text"
            @answeredAll="tiebreakAnswer = $event"
            @missingRequired="tiebreakAnswer = ''"
            @submit="participate()"/>

          <div class="winner-info" v-html="game.winner_text"></div>
        </div>
      </div>
      <div
        v-else-if="step == 'loyalty-shop'"
        class="points-container">
        <div class="image-container">
          <div
            v-if="game.terms_text"
            class="info-icon-bottom-right"
            @click="tocModal = true"
            style="cursor: pointer;" >
            <iconComponent
              class="game-info-icon"
              symbol="info"
              width="18px"/>
          </div>
          <img class="tiebreaker-image" v-if="game.hunt_image" :src="game.hunt_image.url" alt="">
        </div>

        <div class="points-all-points">
          <div class="points-grid">
            <span class="scan-text">{{ user.name }}</span>
            <span class="points-text" :style="`color: ${game.accent_color};`"> {{ participation.points_balance }}</span>
            <!--<span>points</span>-->
          </div>
        </div>
        <div class="countdown">{{ countdown }}</div>

        <div
          v-if="game.leaderboard"
          class="leaderboard">
          <h2>LEADERBOARD</h2>
          <div v-if="!game?.top3Participations?.length">No Points counted yet</div>
          <div
            v-for="(topParticipation, index) in game.top3Participations"
            :key="topParticipation._id"
            class="leaderboard-item"
            :class="{ 'special-leaderboard-item': topParticipation.user._id == user._id }">
            <div v-if="topParticipation.user._id == user._id" class="special-message">
              That's you! Keep it up!
            </div>
            <div class="rank">{{ index + 1 }}</div>
            <div class="user-info">
              <!--<img :src="user.avatar" alt="User Avatar" class="avatar">-->
              <div class="name">{{ topParticipation.user.name }}</div>
            </div>
            <div class="score">{{ topParticipation.points_balance }}</div>
          </div>
        </div>

        <div v-if="expiredShop">The shop is now closed!</div>
        <div v-else>
          <div
            v-for="prize in prizes"
            :key="prize.prize_type"
            class="prize">
            <div
              class="prize--image"
              :style="`background-image: url(${images[prize.prize_type]})`"></div>
            <div class="prize--description">
              <div class="prize--description-wrapper">
                <div class="prize--description-name">
                  {{ prize.prize_type }}
                  {{ prize.cost }}
                </div>
                <div
                  class="prize--description-estimate"
                  :style="`color: ${prize.color || 'black'}`">
                  {{ prize.estimate }}
                </div>
              </div>
              <div
                class="prize--description-button btn btn-activate"
                :class="{ 'btn-disabled': prize.outofstock }"
                @click="claimPrize(prize.prize_type)">Paxa!</div>
            </div>
          </div>
        </div>
        <!--<div
          class="bottom-content text-align-center"
          style="margin: 0 16px;">
          <h3>{{ game.hunt_funfacts_header }}</h3>
          <div v-html="game.hunt_funfacts[randomIndex]"></div>
        </div>-->
      </div>

      <div
        v-else-if="step == 'survey'"
        class="survey-wrapper">

        <!-- Trigger snackbar when finished ? and send email to participation -->
        <div class="survey-container">
          <div class="points-grid" v-if="participation && participation.points">
            <span class="scan-text">{{ game.hunt_header }}</span>
            <span class="points-text" :style="`color: ${game.accent_color};`"> {{ participation.points.length }}</span>
          </div>

          <div class="survey-header">{{ game.survey_header }}</div>
          <Form
            class="survey-all-questions"
            :questions="game.survey"
            :accentColor="game.accent_color"
            :textColor="game.text_color"
            :submitButton="transl.Games.submit"
            @answeredAll="surveyAnswers = $event"
            @missingRequired="surveyAnswers = {}"
            @submit="setSurvey()"/>
        </div>
      </div>
    </div>

    <!--
      In the case of desktop, the scrollbar will affect the poistion of the game-page
      style="padding: 5px 10px 10px 15px"
    -->

    <modal v-if="tocModal" @close="tocModal = false" size="medium" padding="0" :terms="true">
      <slot>
        <div class="terms-wrapper">
          <div v-html="game.terms_text" class="html-text"></div>
        </div>
        <div class="terms-buttons">
          <div
            v-if="authenticated && game.terms_merchandise && !participation.accepted_merchandise && !hasAcceptedGame"
            class="terms-merchandise-grid">
            <inputField
              v-model="acceptMerchandise"
              inputType="checkbox"/>
            <span
              class="terms-merchandise-text"
              v-html="game.terms_merchandise"></span>
          </div>

          <div v-if="authenticated && !hasAcceptedGame" class="btn btn-activate" @click="acceptGameTerms()">
            {{ transl.UserPV.accept }}
          </div>

          <div class="btn btn-secondary" @click="tocModal = false">
            {{ transl.UserPV.close }}
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { cloneDeep as _cloneDeep } from 'lodash-es';
import BeforeEnterMixin from '../../mixins/BeforeEnterMixin';
import EntityMixin from '../../mixins/EntityMixin';
import InputField from '../../stories/components/InputField/InputField';
// import Logo from "../Logo.vue";
import Form from '../../stories/components/Form/Form';

export default {
  name: 'LoyaltyShopHome',
  components: {
    // Logo,
    InputField,
    Form
  },
  props: ['gameId'],
  mixins: [BeforeEnterMixin, EntityMixin],
  data() {
    return {
      loadedPage: false,
      isDevelopment: process.env.NODE_ENV !== 'production',
      gameType: 'LoyaltyShop',
      step: 'loyalty-shop',
      countdown: 0,
      expiredShop: false,
      acceptMerchandise: true,
      acceptTerms: false,
      tocModal: false,
      surveyAnswers: [],
      firstTime: true,
      disableTryAgain: false,
      activateErrorMsg: null,
      currentUnix: (new moment).unix(),
      timeInterval: null,
      randomIndex: 0,
      enlargedMap: false,
      images: {
        premium: 'https://via.placeholder.com/150',
        medium: 'https://via.placeholder.com/150',
        budget: 'https://via.placeholder.com/150',
        // More images...
      },
      claimedPrize: null,
      userInfo: {
        claimedPrizeId: null,
      },
    };
  },
  mounted() {
    this.getGame();
    /*this.timeInterval = setInterval(() => {
      this.currentUnix = (new moment).unix();
      // TODO: if game starts during time interval (reload page? or set canAddParticipation = true)
    }, 1000);*/
  },
  destroyed() {
    // clearTimeout(this.timeInterval);
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getGame();
      }
    },
  },
  computed: {
    ...mapGetters([
      'authenticated',
      'user',
      'participation',
      'canAddParticipation',
      'reason',
      'prizes',
    ]),
    cssProps() {
      return {
        '--game-desktop-bg-color': this.game.desktop_bg_color,
        '--game-bg-color': this.game.bg_color,
        '--game-accent-color': this.game.accent_color,
        '--game-text-color': this.game.text_color,
      }
    },
    hasCurrentParticipation() {
      return this.participation && this.participation._id;
    },
    hasAcceptedGame() {
      if (!this.user || !this.user._id) {
        return false;
      }
      for (let i = 0; i < this.user.accepted_games.length; i++) {
        const acceptedGame = this.user.accepted_games[i];
        if (acceptedGame._id === this.gameId
          || acceptedGame.unique_name === this.gameId) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getGame() {
      this.$store.dispatch('getGame', this.attach({ authenticated: this.authenticated }))
      .then((success) => {
        if (!success) {
          if (!this.game || !this.game._id) {
            this.setAlert(this.reason);
            this.$router.push({ name: 'UserStart' });
            return;
          }
          this.gotoError();
          return;
        }

        this.formatTime();
        setInterval(() => {
          this.formatTime();
        }, 1000);

        this.setPageTitle(this.game.name, this.game.company.name);
        this.acceptMerchandise = this.game.terms_merchandise_default;
        // this.setRandomFact();
        this.pushDataLayer({
          parentCompany: this.game.company.parentCompany,
          company: this.game.company,
          game: this.game,
          action: 'Enter',
        });

        this.expiredShop = this.game.expires_at && this.game.expires_at < this.currentUnix;

        this.setStep();
      });
    },
    setStep() {
      const game = this.game;
      const participation = this.participation;
      if ((!this.authenticated && game.must_login)
        || (!this.hasCurrentParticipation && !game.tiebreak)) {
        this.gotoOnboarding();
      } else if (!this.hasCurrentParticipation && !this.canAddParticipation) {
        this.gotoError();
      } else if (!this.hasCurrentParticipation && game.tiebreak) {
        this.step = 'tiebreaker';
        if (game.terms_must_accept && !this.hasAcceptedGame) {
          this.tocModal = true;
        }
      } else if (participation.claims?.length && !participation.survey_answers?.length && this.game.survey?.length) {
        this.step = 'survey';
      } else {
        this.step = 'loyalty-shop';
      }
      this.loadedPage = true;
    },
    claimPrize(name) {
      this.$store.dispatch("claimPrize", this.attach({
        offerType: name
      })).then(({ success, message, prize }) => {
        if (success) {
          // this.step = 'address';
          this.claimedPrize = prize;
          this.userInfo.claimedPrizeId = prize._id;
        } else {
          this.setAlert(message, 5);
        }
      });
    },
    /*setRandomFact() {
      if (this.game.hunt_funfacts && this.game.hunt_funfacts.length) {
        this.randomIndex = this.getRandomInt(this.game.hunt_funfacts.length);
      }
    },*/
    acceptGameTerms() {
      this.tocModal = false;
      this.acceptTerms = true;
    },
    setSurvey() {
      this.$store.dispatch('answerGameSurvey', this.attach({ answers: this.surveyAnswers }))
      .then((success) => {
        if (!success) {
          this.setAlert(this.reason);
          return;
        }

        this.setStep();
      });
    },
    attach(obj) {
      obj.entityId = this.gameId;
      obj.entityType = this.gameType;
      return obj;
    },
    gotoError() {
      console.log('Home gotoError');
      this.$router.push({ name: 'LoyaltyShopError' });
    },
    gotoOnboarding() {
      console.log('Home gotoOnboarding');
      this.$router.push({ name: 'LoyaltyShopOnboarding' });
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    ctaDoneRedirect() {
      if (this.game.cta_done_url) {
        this.openUrl(this.game.cta_done_url, true);
      } else {
        this.$router.push({ name: 'HomeCompany', params: { companyId: this.getId(this.game.company) } });
      }
    },
    formatTime() {
      const unix = (new moment).unix();
      const duration = moment.duration(this.game.expires_at - unix, 'seconds');
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
        this.expiredShop = true;
      }
      this.countdown = '';
      let largerExists = false;
      if (days > 0) {
        this.countdown += `${days}:`;
        largerExists = true;
      }
      if (hours > 0 || largerExists) {
        this.countdown += `${hours}:`;
        largerExists = true;
      }
      if (minutes > 0 || largerExists) {
        this.countdown += `${minutes}:`;
        largerExists = true;
      }
      if (seconds > 0 || largerExists) {
        this.countdown += `${seconds}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/games/LoyaltyShop/Base";
@import "../../assets/scss/games/LoyaltyShop/Home";
</style>
