/* eslint-disable */
// @ts-nocheck

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import store from './store';
import router from './router';

// import VueGtm from '@gtm-support/vue2-gtm';
/*import index from './router';
import companyRouter from './router/companyRouter';*/
import { detectMarketFromLocation } from './detect-market';
import App from './App.vue';
import interceptor from './resources/response-interceptor';
import {
  MdField, MdButton, MdRadio,
  MdCheckbox, MdList, MdTabs,
  MdTable, MdContent, MdCard, MdMenu, MdDatepicker,
  MdChips
} from 'vue-material/dist/components';
import VueClipboard from 'vue-clipboard2';
import Vue2TouchEvents from 'vue2-touch-events';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import PortalVue from 'portal-vue'

import './registerServiceWorker';

import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'cropperjs/dist/cropper.css';

import IconComponent from './stories/components/IconComponent/IconComponent.vue';
import Modal from './stories/components/Modal/Modal.vue';
import GlobalMixin from './mixins/GlobalMixin.vue';

const market = detectMarketFromLocation(window.location);
const originAndMarket = `${window.origin}/${market}`;
function setBasePath () {
  if (!market) return;
  axios.defaults.baseURL = `/${market}`;
}

setBasePath();
axios.defaults.headers.common['local-language'] = localStorage.getItem('localLanguage');

Vue.component('vuePhoneNumberInput', VuePhoneNumberInput);
Vue.component('iconComponent', IconComponent);
Vue.component('modal', Modal);
Vue.mixin(GlobalMixin);

// Vue.prototype.$http = axios;
Vue.use(Vuex);
Vue.use(MdField);
Vue.use(MdRadio);
Vue.use(MdButton);
Vue.use(MdCheckbox);
Vue.use(MdList);
Vue.use(MdTabs);
Vue.use(MdTable);
Vue.use(MdContent);
Vue.use(MdCard);
Vue.use(MdMenu);
Vue.use(MdDatepicker);
Vue.use(MdChips);
Vue.use(PortalVue);
Vue.use(VueClipboard);
Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

const host = window.location.host;

// const socket = io(originAndMarket);
const socket = io(window.origin, {
  transports: ['websocket'],
  upgrade: false // Disable HTTP long-polling
});
socket.on('connect', () => {
  console.log('WebSocket connection established');
});

socket.on('connect_error', (error) => {
  console.log('WebSocket connection error:', error);
});
Vue.use(VueSocketIOExt, socket);

// default dpAnalytics
Vue.prototype.$dpAnalytics = (data) => {
  // Enginio dashboard
  let defaultDashboard = '6038c81007c62ebc88353c9a';
  if (process.env.NODE_ENV !== 'production' || host.includes('test.enginio')) {
    console.log('Insights: ', data);
  } else {
    axios.post(`https://dp-insights.com/api/observations`, data, {
      auth: {
        username: 'client',
        password: localStorage.getItem('dashboard') || defaultDashboard,
      }
    });
  }
};

// Enable GTM
/*Vue.prototype.$enableGTM = (enabled = false) => {
  Vue.use(VueGtm, {
    id: 'GTM-NDNDN94',
    enabled, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    debug: true, // Whether or not display console logs debugs (optional)
    // defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    // compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    // nonce: '2726c7f26c', // Will add `nonce` to the script tag
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  });
};*/

// For translation with variables to work properly

// can be used anywhere in the frontend
// JS:
// console.log("hello: ${name}!".interpolate({ name: 'Anton' }));
// HTML:
// {{ "hello: ${name}!".interpolate({ name: 'Anton' }) }}

String.prototype.interpolate = function (params) {
  try {
    const keys = Object.keys(params);
    const definedParams = {};
    keys.forEach(function (key) {
      try {
        definedParams[key] = params[key] === undefined ? '' : params[key];
      } catch (err) {
        console.log('ERROR interpolate loop: ', err);
        definedParams[key] = '';
      }
    });
    const definedKeys = Object.keys(definedParams);
    const definedValues = Object.values(definedParams);
    let result = new Function(...definedKeys, `return \`${this}\`;`)(...definedValues);
    result = result.replace(/\$\{[^}]+\}/g, '');
    return result;
  } catch (error) {
    console.log('ERROR interpolate: ', error);
    // const result = this.replace(/\$\{[^}]+\}/g, '');
    return this;
  }
}


new Vue({
  store,
  router,
  interceptor,
  render: h => h(App),
}).$mount('#app');

