
<template>
  <div
    v-if="loadedPage"
    class="admin-qr-hunt-content--container">

    <EntityEditor
      v-if="loadedPage"
      :title="game.name"
      :entityType="`${gameType}Game`"
      :entity="game"
      :admin="true"/>

    <div v-if="game && game._id">
      <div style="padding: 10px; background: white;">
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 10px; margin-bottom: 30px;">
          <h1 class="md-title">{{ game.participationsLength }} Participations</h1>
          <h1 class="md-title">{{ game.participationsCtaDoneLength }} CTA_DONE</h1>
          <h1 class="md-title">{{ game.limitedOffersAllLength }} Codes</h1>
        </div>
        <table v-if="Object.keys(game.limitedOffersByType || {}).length">
          <h1 class="md-title">Unclaimed Codes per type:</h1>
          <tr>
            <td><b>Prize type</b></td>
            <td><b>Unclaimed</b></td>
            <td><b>Claimed</b></td>
          </tr>
          <tr
            v-for="(limitedOffer, key) in game.limitedOffersByType"
            :key="key">
            <td>{{ key }}</td>
            <td>{{ limitedOffer.unclaimed }}</td>
            <td>{{ limitedOffer.claimed }}</td>
          </tr>
        </table>
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 10px;">
          <span class="btn btn-activate" @click="showListModal = true">Get List</span>
          <span class="btn btn-activate" @click="show3rdPartyListModal = true">Get 3rd party List</span>
          <span class="btn btn-activate" @click="showCTAModal = true">Get CTA's</span>
          <a
            class="btn btn-activate"  
            :href="`${windowOrigin}/admin/participations?game=${game._id}`">
            Find Participations
          </a>
        </div>
      </div>

      <modal
        v-if="showListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
        <slot>
          <h3>Filters</h3>
          <md-datepicker
            v-model="statCreatedStart">
            <label>Created Start</label>
          </md-datepicker>
          <md-datepicker
            v-model="statCreatedEnd">
            <label>Created End</label>
          </md-datepicker>
          <md-field>
            <label for="cta_done">cta_done?</label>
            <md-select
              v-model="ctaDone">
              <md-option value="all">Both</md-option>
              <md-option :value="true">cta_done == true</md-option>
              <md-option :value="false">cta_done == false</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label for="administrated">administrated?</label>
            <md-select
              v-model="administrated">
              <md-option value="all">Both</md-option>
              <md-option :value="true">administrated == true</md-option>
              <md-option :value="false">administrated == false</md-option>
            </md-select>
          </md-field>
          <h3>Change selection</h3>
          <md-field>
            <label for="administrated">Set Administrated</label>
            <md-select
              v-model="setAdministrated">
              <md-option value="all">Set all</md-option>
              <md-option value="cta_done">Set all (cta_done == true)</md-option>
              <md-option :value="false">Don't set</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getList()">Download List</button>
            <button class="btn btn-transparent" @click="showListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="show3rdPartyListModal"
        size="xlarge"
        modalType="fullscreen"
        @close="show3rdPartyListModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
        <slot>
          <div style="text-align: left;">
            <h3>Important!</h3>
            <p>When you download this list, the following will happen:</p>
            <ul>
              <li>All participations will be cta_done == true</li>
              <li>All participations will be administrated == false</li>
              <li>The participations you get will be set to administrated = true</li>
            </ul>
            <p>All of this means that the next time you click this button, you will only get participations that are administrated == false, ie repeat the steps above</p>
          </div>
          <div>
            <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
            <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
          </div>
        </slot>
      </modal>

      <modal
        v-if="showCTAModal"
        size="xlarge"
        modalType="fullscreen"
        @close="showCTAModal = false;"
        :header="{ closeButton: true, textPosition: 'center', text: 'Get CTA\'s' }">
        <slot>
          <md-field>
            <label for="administrated">won?</label>
            <md-select
              v-model="won">
              <md-option value="all">Both</md-option>
              <md-option :value="true">won == true</md-option>
              <md-option :value="false">won == false</md-option>
            </md-select>
          </md-field>
          <div>
            <button class="btn btn-activate" @click="getUnclaimed()">Download CTA's</button>
            <button class="btn btn-transparent" @click="showCTAModal = false">Close</button>
          </div>
        </slot>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { mapGetters } from "vuex";
import EventBus from "../resources/eventBus";
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import EntityEditor from '../stories/components/EntityEditor/EntityEditor.vue';

export default {
  name: 'AdminGamesQrHunt',
  components: {
    EntityEditor
  },
  props: [
    'gameType',
    'gameId'
  ],
  mixins: [BeforeEnterMixin],
  mounted() {
    this.loadPage();
    this.setPageTitle('Admin', this.gameType);
  },
  destroyed() {
    EventBus.$emit('selected', '');
  },
  computed: {
    ...mapGetters('games', ["game", "userStatus"]),
  },
  watch: {
    gameId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPage();
      }
    }
  },
  data() {
    return {
      loadedPage: false,
      showListModal: false,
      showCTAModal: false,
      show3rdPartyListModal: false,
      won: 'all',
      statCreatedStart: 1672578061000,
      statCreatedEnd: (new moment()).valueOf(),
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: false,
      setAdministrated: false,
      ctaDone: false,
    };
  },
  methods: {
    loadPage() {
      this.loadedPage = false;
      this.$store.dispatch('games/getAdminGame', {
        entityType: this.gameType,
        entityId: this.gameId,
      }).then(() => {
        this.loadedPage = true;
      });
    },
    getList() {
      axios.get(`/api/admin/games/${this.gameType}/${this.gameId}/participations/csv`
        +`?createdStart=${this.statCreatedStart/1000}&createdEnd=${this.statCreatedEnd/1000}&setAdministrated=${this.setAdministrated}&administrated=${this.administrated}&ctaDone=${this.ctaDone}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.game.name}_participations_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    get3rdPartyList() {
      axios.get(`/api/admin/games/${this.gameType}/${this.gameId}/participations/csv`
        +`?setAdministrated=cta_done&administrated=false&ctaDone=true`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `thirdparty_${this.game.name || this.gameId}_participations_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getUnclaimed() {
      axios.get(`/api/admin/games/${this.gameType}/${this.gameId}/limited-ctas/csv?won=${this.won}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.game.name}_unclaimed_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/pages/AdminGame";
</style>
