<template>
  <div
    v-if="loadedPage"
    class="receipt-list">

    <div
      class="the-list">
      <md-table>
        <md-table-toolbar class="list-toolbar">
          <div>
            <h1 v-if="receiptsTotal" class="md-title">{{ receiptsTotal }} Receipts</h1>
            <h1 v-else class="md-title">{{ receipts.length >= 50 ? '> 50' : receipts.length }} Receipts</h1>
          </div>
          <InputField
            v-model="search"
            placeholder="Find receipt..."
            trailingIcon="magnifyingGlass"
            clickableTrailingIcon
            @trailingIconClick="findAdminAllReceipts()"
            @enterKey="findAdminAllReceipts()"/>

          <div
            class="column-picker"
            @focus="activationOptions = true"
            @blur="activationOptions = false">
            <button
              class="btn btn-activate"
              @click="activationOptions = !activationOptions">
              Activations
            </button>
            <div
              v-show="activationOptions"
              class="column-options">
              <InputField
                v-model="actSearch"
                placeholder="Find activation..."
                class="column-search"
                @input="findAdminPVActivation()"/>
              <div
                class="column-option"
                v-for="(activation, index) in activationPicker"
                :class="{ 'active-column-option': activation.active }"
                :key="`column-option-${index}-${activation.name}`"
                @click="activateActivationFilter(activation)">
                {{ activation.company.name }} - {{ activation.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="retailerOptions = true"
            @blur="retailerOptions = false">
            <button
              class="btn btn-activate"
              @click="retailerOptions = !retailerOptions">
              Retailers
            </button>
            <div
              v-show="retailerOptions"
              class="column-options">
              <InputField
                v-model="retSearch"
                placeholder="Find retailer..."
                class="column-search"
                @input="findAdminPVRetailer()"/>
              <div
                class="column-option"
                v-for="(retailer, index) in retailerPicker"
                :class="{ 'active-column-option': retailer.active }"
                :key="`column-option-${index}-${retailer.name}`"
                @click="activateRetailerFilter(retailer)">
                {{ retailer.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="statusOptions = true"
            @blur="statusOptions = false">
            <button
              class="btn btn-activate"
              @click="statusOptions = !statusOptions">
              Status
            </button>
            <div
              v-show="statusOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="status in statuses"
                :class="{ 'active-column-option': status.active }"
                :key="`column-option-${status.id}`"
                @click="activateStatusFilter(status)">
                {{ status.name }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="filterOptions = true"
            @blur="filterOptions = false">
            <button
              class="btn btn-activate"
              @click="filterOptions = !filterOptions">
              Filters
            </button>
            <div
              v-show="filterOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="filter in filters"
                :class="{ 'active-column-option': filter.active }"
                :key="`column-option-${filter.id}`">
                <div @click="filter.func ? filter.func() : filter.active = !filter.active">
                  {{ filter.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="columnOptions = true"
            @blur="columnOptions = false">
            <button
              class="btn btn-activate"
              @click="columnOptions = !columnOptions">
              Columns
            </button>
            <div
              v-show="columnOptions"
              class="column-options">
              <div
                class="column-option"
                v-for="column in columns"
                :class="{ 'active-column-option': column.active }"
                :key="`column-option-${column.title}`"
                @click="column.active = !column.active">
                {{ column.title }}
              </div>
            </div>
          </div>
          <div
            class="column-picker"
            @focus="statOptions = true"
            @blur="statOptions = false">
            <button
              class="btn btn-activate"
              :class="{ 'btn-disabled': !activationId }"
              @click="statOptions = !statOptions">
              Functions
            </button>
            <div
              v-show="statOptions"
              class="column-options">
              <div class="column-option">
                NOTE: the search will not be applied to any of these lists (yet)
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showListModal = true">Get List</button>
              </div>
              <div v-if="admin" class="column-option">
                <button class="btn btn-activate" @click="show3rdPartyListModal = true">Get 3rd party List</button>
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showStatsModal = true">Get Stats</button>
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="showCTAModal = true">Get CTA's</button>
              </div>
              <div class="column-option">
                <button class="btn btn-activate" @click="communicationModal = true">Create Communication</button>
              </div>
            </div>
          </div>
        </md-table-toolbar>
        <!--style="position: sticky; top: 0; z-index: 1;" dosnt work..-->
        <md-table-row>
          <md-table-head
            v-for="column in activeColumns"
            :key="`title-${column.title}`">
            {{ column.title }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="receipt in receipts"
          :key="receipt._id">
          <md-table-cell
            v-for="column in activeColumns"
            :key="`row-${column.title}`">
            <div
              v-if="column.title == 'Buttons'"
              class="btn-wrapper">
              <router-link
                class="btn btn-activate btn-small"
                :to="{ name: `${userType}PVReceipt`, params: { receiptId: receipt._id } }">Arrows</router-link>
              <a
                class="btn btn-transparent btn-small"
                :href="`${windowOrigin}/admin/pv/receipts/${receipt._id}`"
                target="_blank">Tab</a>
            </div>
            <div v-else-if="!column.exclude">
              {{ getValue(receipt, column) }}
              <span v-if="column.edit" @click="openEditModal(receipt, column.db)">
                <svg style="vertical-align: top; cursor: pointer;" width="12px" height="12px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                  <rect id="view-box" width="24" height="24" fill="none"/>
                  <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#141124"/>
                </svg>
              </span>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--<md-table-row>-->
      <div class="pagination">
        <button
          :class="{ 'btn-disabled': first == 0 }"
          class="btn btn-activate"
          @click="paginate(-1)">
          Previous
        </button>
        <div style="align-self: center;">Page {{ first == 0 ? '1' : (first / number)+1 }}</div>
        <button
          :class="{ 'btn-disabled': receipts.length < 50 }"
          class="btn btn-activate"
          @click="paginate(1)">
          Next
        </button>
      </div>
    </div>

    <modal
      v-if="editModal"
      size="xlarge"
      modalType="fullscreen"
      @close="editModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Edit receipt data' }">
      <slot>
        <div>
          <InputField
            v-model="editValue"
            @enterKey="updateReceipt()"/>
        </div>
        <div>
          <button
            class="btn btn-activate"
            @click="updateReceipt()">
            Update
          </button>
          <button
            class="btn btn-transparent"
            @click="editModal = false">
            Close
          </button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get List' }">
      <slot>
        <h3>Filters</h3>
        <md-datepicker
          v-model="statCreatedStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="statCreatedEnd">
          <label>Created End</label>
        </md-datepicker>
        <md-field>
          <label for="cta_done">cta_done?</label>
          <md-select
            v-model="ctaDone">
            <md-option value="all">Both</md-option>
            <md-option :value="true">cta_done == true</md-option>
            <md-option :value="false">cta_done == false</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label for="administrated">administrated?</label>
          <md-select
            v-model="administrated">
            <md-option value="all">Both</md-option>
            <md-option :value="true">administrated == true</md-option>
            <md-option :value="false">administrated == false</md-option>
          </md-select>
        </md-field>
        <h3>Change selection</h3>
        <md-field>
          <label for="administrated">Set Administrated</label>
          <md-select
            v-model="setAdministrated">
            <md-option value="all">Set all</md-option>
            <md-option value="cta_done">Set all (cta_done == true)</md-option>
            <md-option :value="false">Don't set</md-option>
          </md-select>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getList()">Download List</button>
          <button class="btn btn-transparent" @click="showListModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="show3rdPartyListModal"
      size="xlarge"
      modalType="fullscreen"
      @close="show3rdPartyListModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get 3rd party List' }">
      <slot>
        <div style="text-align: left;">
          <h3>Important!</h3>
          <p>When you download this list, the following will happen:</p>
          <ul>
            <li>All receipts will be cta_done == true</li>
            <li>All receipts will be administrated == false</li>
            <li>The receipts you get will be set to administrated = true</li>
          </ul>
          <p>All of this means that the next time you click this button, you will only get receipts that are administrated == false, ie repeat the steps above</p>
        </div>
        <div>
          <button class="btn btn-activate" @click="get3rdPartyList()">Download List</button>
          <button class="btn btn-transparent" @click="show3rdPartyListModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showStatsModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showStatsModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get Stats' }">
      <slot>
        <h3>Do you want to filter on a store?</h3>
        <md-field>
          <label>Store</label>
          <md-input v-model="receiptStore"></md-input>
        </md-field>
        <md-field>
          <label>Top Stores, per store address</label>
          <md-input v-model="receiptStoreTop" placeholder="20"></md-input>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getStats()">Download Stats</button>
          <button class="btn btn-transparent" @click="showStatsModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="showCTAModal"
      size="xlarge"
      modalType="fullscreen"
      @close="showCTAModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Get CTA\'s' }">
      <slot>
        <md-field>
          <label for="administrated">won?</label>
          <md-select
            v-model="won">
            <md-option value="all">Both</md-option>
            <md-option :value="true">won == true</md-option>
            <md-option :value="false">won == false</md-option>
          </md-select>
        </md-field>
        <div>
          <button class="btn btn-activate" @click="getUnclaimed()">Download CTA's</button>
          <button class="btn btn-transparent" @click="showCTAModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="communicationModal"
      size="xlarge"
      modalType="fullscreen"
      @close="communicationModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Create Communication' }">
      <slot>
        <div>Creating a Communication will make it possible for you to communicate with all these users in your search</div>
        <InputField
          v-model="newCommunicationName"
          placeholder="Communication name"/>
        <div>
          <button class="btn btn-activate" @click="createCommunication()">Create Communication</button>
          <button class="btn btn-transparent" @click="communicationModal = false">Close</button>
        </div>
      </slot>
    </modal>

    <modal
      v-if="dateModal"
      size="xlarge"
      modalType="fullscreen"
      @close="dateModal = false;"
      :header="{ closeButton: true, textPosition: 'center', text: 'Set filter for created-date' }">
      <slot>
        <md-datepicker
          v-model="createdStart">
          <label>Created Start</label>
        </md-datepicker>
        <md-datepicker
          v-model="createdEnd">
          <label>Created End</label>
        </md-datepicker>
        <div>
          <button class="btn btn-activate" @click="applyDateFilter()">Apply</button>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// import { cloneDeep as _cloneDeep } from 'lodash-es';
import moment, { unix } from 'moment-timezone';
import { debounce as _debounce } from 'lodash-es';
import InputField from '../InputField/InputField';
import { first } from 'lodash';

export default {
  name: "ReceiptList",
  components: {
    InputField,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.findAdminAllReceipts = _debounce(() => {
      this.$store.dispatch(`pvActivations/find${this.userType}AllReceipts`, {
        key: this.key,
        search: this.search,
        filters: this.getActiveFilters(),
        activationId: this.activationId,
        retailerId: this.retailerId,
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
        first: this.first,
        number: this.number,
      }).then(() => {
        this.loadedReceipts = true;
      });
    }, 300);

    this.findAdminPVActivation = _debounce(() => {
      this.$store.dispatch(`pvActivations/find${this.userType}PVActivation`, {
        query: this.actSearch,
      }).then(() => {
        this.loadedActivations = true;
      });
    }, 300);

    this.findAdminPVRetailer = _debounce(() => {
      this.$store.dispatch(`pvRetailers/find${this.userType}PVRetailer`, {
        query: this.retSearch,
      }).then(() => {
        this.loadedRetailers = true;
      });
    }, 300);
  },
  beforeUnmount() {
    this.findAdminAllReceipts.cancel();
    this.findAdminPVActivation.cancel();
    this.findAdminPVRetailer.cancel();
  },
  mounted() {
    this.setActiveSearch();
    this.setActiveColumns();
    this.setActiveStatus();
    this.setActiveFilters();
    this.setActivationId();
    this.setRetailerId();
    this.setPagination();

    this.findAdminAllReceipts();
    this.findAdminPVActivation();
    this.findAdminPVRetailer();
  },
  computed: {
    ...mapGetters('pvActivations', ['activations', 'receipts', 'receiptsTotal']),
    ...mapGetters('pvRetailers', ['retailers']),
    userType() {
      return this.admin ? 'Admin' : 'Company';
    },
    userApi() {
      return this.admin ? 'admin' : 'c';
    },
    loadedPage() {
      return this.loadedActivations && this.loadedReceipts;
    },
    activeColumns() {
      return this.columns.filter((column) => {
        return column.active;
      });
    },
    activationPicker() {
      let activationPicker = [];
      if (this.activations && this.activations.length) {
        for (let i = 0; i < this.activations.length; i++) {
          const activation = this.activations[i];
          activationPicker.push(activation);
          if (activation._id == this.activationId) {
            activationPicker[activationPicker.length-1].active = true;
          }
        }
      }
      activationPicker.sort(function (a, b) {
        if (a.company.name < b.company.name) {
          return -1;
        }
        if (a.company.name > b.company.name) {
          return 1;
        }
        return 0;
      });
      return activationPicker;
    },
    retailerPicker() {
      let retailerPicker = [];
      if (this.retailers && this.retailers.length) {
        for (let i = 0; i < this.retailers.length; i++) {
          const retailer = this.retailers[i];
          retailerPicker.push(retailer);
        }
      }
      return retailerPicker;
    },
  },
  data() {
    return {
      loadedReceipts: false,
      loadedActivations: false,
      statusOptions: false,
      filterOptions: false,
      columnOptions: false,
      activationOptions: false,
      retailerOptions: false,
      statOptions: false,
      dateModal: false,
      key: '',
      search: '',
      actSearch: '',
      retSearch: '',
      statuses: [
        { id: 'placeholder', name: 'Placeholder created', active: false, filter: { status: 'PLACEHOLDER_CREATED' }},
        { id: 'termscreated', name: 'Terms created', active: false, filter: { status: 'TERMS_CREATED' }},
        { id: 'receiptconfirmed', name: 'Receipt confirmed', active: false, filter: { status: 'RECEIPT_CONFIRMED' }},
        { id: 'receiptdeclined', name: 'Receipt declined', active: false, filter: { status: 'RECEIPT_DECLINED' }},
        { id: 'regconfirmed', name: 'Registration confirmed', active: false, filter: { status: 'REGISTRATION_CONFIRMED' }},
        { id: 'regdeclined', name: 'Registration declined', active: false, filter: { status: 'REGISTRATION_DECLINED' }},
        { id: 'addressconfirmed', name: 'Address confirmed', active: false, filter: { status: 'ADDRESS_CONFIRMED' }},
        { id: 'paymentconfirmed', name: 'Payment confirmed', active: false, filter: { status: 'PAYMENT_CONFIRMED' }},
        { id: 'surveyconfirmed', name: 'Survey confirmed', active: false, filter: { status: 'SURVEY_CONFIRMED' }},
        { id: 'reviewconfirmed', name: 'Review confirmed', active: false, filter: { status: 'REVIEW_CONFIRMED' }},
        { id: 'cashbackconfirmed', name: 'Cashback confirmed', active: false, filter: { status: 'CASHBACK_CONFIRMED' }},
        { id: 'cashbackdoneconfirmed', name: 'Cashback done confirmed', active: false, filter: { status: 'CASHBACK_DONE' }},
        { id: 'cashbackdeclined', name: 'Cashback declined', active: false, filter: { status: 'CASHBACK_DECLINED' }},
        { id: 'ctadone', name: 'Cta done', active: false, filter: { status: 'CTA_DONE' }},
        { id: 'returnok', name: 'Return OK', active: false, filter: { status: 'RETURN_OK' }},
        { id: 'sendok', name: 'Send OK', active: false, filter: { status: 'SEND_OK' }},
        { id: 'stuck', name: 'Stuck', active: false, filter: { status: { $regex: 'STUCK', $options: 'i' }}},
        { id: 'runninganalyzer', name: 'Running analyzer', active: false, filter: { running_analyzer: true }},
      ],
      filters: [
        { id: 'datemodal', name: 'Date', active: true,
          func: () => {
            this.dateModal = !this.dateModal;
          }
        },
        { id: 'withcomments', name: 'With comments', active: false, filter: { comments: { $ne: [] } } },
        { id: 'flagged', name: 'Flagged', active: false, filter: { flagged: true }},
        { id: 'noplaceholder', name: 'No placeholder', active: false, filter: { status: { $ne: 'PLACEHOLDER_CREATED' } }},
        { id: 'receiptnotconfirmed', name: 'Receipt not confirmed', active: false, filter: {
          $and: [
            { receipt_analyze_attempts: { $gte: 1 }},
            { status: { $ne: 'RECEIPT_CONFIRMED' }},
            { status: { $ne: 'REGISTRATION_CONFIRMED' }},
            { status: { $ne: 'REGISTRATION_DECLINED' }},
            { status: { $ne: 'ADDRESS_CONFIRMED' }},
            { status: { $ne: 'SURVEY_CONFIRMED' }},
            { status: { $ne: 'CASHBACK_CREATED' }},
            { status: { $ne: 'CASHBACK_CONFIRMED' }},
            { status: { $ne: 'CASHBACK_DONE' }},
            { status: { $ne: 'CASHBACK_DECLINED' }},
            { status: { $ne: 'CTA_DONE' }},
          ]
        }},
        { id: 'regnotconfirmed', name: 'Registration not confirmed', active: false, filter: {
          $and: [
            { confirmed_receipt: true },
            { status: { $ne: 'REGISTRATION_CONFIRMED' }},
            { status: { $ne: 'ADDRESS_CONFIRMED' }},
            { status: { $ne: 'SURVEY_CONFIRMED' }},
            { status: { $ne: 'CASHBACK_CREATED' }},
            { status: { $ne: 'CASHBACK_CONFIRMED' }},
            { status: { $ne: 'CASHBACK_DONE' }},
            { status: { $ne: 'CASHBACK_DECLINED' }},
            { status: { $ne: 'CTA_DONE' }},
          ]
        }},
        { id: 'cashbacknotconfirmed', name: 'Cashback not confirmed', active: false, filter: {
          $and: [
            { cta_done: true },
            { status: { $ne: 'CASHBACK_CREATED' }},
            { status: { $ne: 'CASHBACK_CONFIRMED' }},
            { status: { $ne: 'CASHBACK_DONE' }},
            { status: { $ne: 'CASHBACK_DECLINED' }},
          ]
        }},
        { id: 'cashbackdonenotconfirmed', name: 'Cashback done, not confirmed', active: false, filter: {
          $and: [
            { cta_done: true },
            { status: { $ne: 'CASHBACK_DONE' }},
          ]
        }},
        { id: 'ctanotdone', name: 'Cta not done', active: false, filter: {
          $and: [
            { status: { $ne: 'CTA_DONE' }},
          ]
        }},
        { id: 'admininsteps', name: 'Admin in steps', active: false, filter: { 'steps.role': { $eq: 'Admin' }}},
        { id: 'adminnotinsteps', name: 'Admin not in steps', active: false, filter: { 'steps.role': { $ne: 'Admin' }}},
        { id: 'pdfs', name: 'Pdf:s', active: false, filter: { 'media.fileType': 'application/pdf' }},
        { id: 'executedactions', name: 'Executed actions', active: false, filter: { cta_executed_actions: { $exists: true, $ne: [] } }},
        { id: 'expectedvsactualrewards', name: 'Expected != Actual rewards', active: false, filter: {
          $expr: { $ne: ["$cta_code_expected_number", "$cta_code_actual_number"] }
        }},
        { id: 'hasctaissue', name: 'Has cta issue (OOS)', active: false, filter: { cta_issue: 'The codes are out of stock' } },
      ],
      activationId: '',
      retailerId: '',
      first: 0,
      number: 50,
      columns: [
        { title: 'Buttons', db: 'buttons', active: true },
        { title: 'Comp name', active: false, db: 'company.name', type: 'string' },
        { title: 'Activation name', active: true, db: 'activation.name', type: 'string' },
        { title: 'Activation id', active: false, db: 'activation._id', type: 'string' },
        { title: 'Receipt id', active: false, db: '_id', type: 'string' },
        { title: 'Name', active: true, db: 'name+last_name', type: 'string' },
        { title: 'Phone Nr', active: true, db: 'phone_nr', type: 'string' },
        { title: 'Email', active: false, db: 'email', type: 'string', edit: true },
        { title: 'Receipt_items', active: true, type: 'array', db: 'receipt_items', value: 'item' },
        { title: 'Receipt_type', active: false, type: 'string', db: 'receipt_type' },
        { title: 'Receipt_analyze_attempts', active: false, type: 'number', db: 'receipt_analyze_attempts' },
        { title: 'Receipt_item_match', active: false, type: 'string', db: 'receipt_item_match', edit: true },
        { title: 'Receipt_item_cost', active: false, type: 'string', db: 'receipt_item_cost', edit: true },
        { title: 'Receipt_store', active: false, type: 'string', db: 'receipt_store', edit: true },
        { title: 'Receipt_date_unix', active: false, type: 'unix', db: 'receipt_date_unix', edit: true },
        { title: 'Receipt_number', active: false, type: 'string', db: 'receipt_number', edit: true },
        { title: 'Receipt_address', active: false, type: 'string', db: 'receipt_address', edit: true },
        { title: 'Receipt_phone', active: false, type: 'string', db: 'receipt_phone', edit: true },
        { title: 'Receipt_total', active: false, type: 'string', db: 'receipt_total', edit: true },
        { title: 'Receipt_issue', active: true, type: 'string', db: 'receipt_issue', edit: true },
        { title: 'Changed_receipt_data', active: false, db: 'changed_receipt_data', type: 'boolean', edit: true },
        { title: 'Confirmed_receipt', active: true, db: 'confirmed_receipt', type: 'boolean' },
        { title: 'Receipt_confidence', active: false, db: 'receipt_confidence', type: 'number-floor' },
        { title: 'Serialnrs', active: true, type: 'array', db: 'receipt_items', value: 'serialnr' },
        { title: 'Imeinrs', active: true, type: 'array', db: 'receipt_items', value: 'imeinr' },
        { title: 'Modelnrs', active: true, type: 'array', db: 'receipt_items', value: 'modelnr' },
        { title: 'Confirmed_registration', active: true, db: 'confirmed_registration', type: 'boolean' },
        { title: 'Confirmed_survey', active: true, db: 'confirmed_survey', type: 'boolean' },
        { title: 'Confirmed_address', active: true, db: 'confirmed_address', type: 'boolean' },
        { title: 'Name at Address', active: false, db: 'name_at_address', type: 'string' },
        { title: 'Address', active: false, db: 'address', type: 'string' },
        { title: 'Postal_code', active: false, db: 'postal_code', type: 'string' },
        { title: 'City', active: false, db: 'city', type: 'string' },
        { title: 'State', active: false, db: 'state', type: 'string' },
        { title: 'Country', active: false, db: 'country', type: 'string' },
        { title: 'Cta_done', active: true, db: 'cta_done', type: 'boolean' },
        { title: 'Cta_done_at', active: false, type: 'unix', db: 'cta_done_at' },
        { title: 'Cta_issue', active: false, type: 'string', db: 'cta_issue' },
        { title: 'Cta_code', active: false, type: 'string', db: 'cta_code' },
        { title: 'Expected nr cta codes', active: false, type: 'number', db: 'cta_code_expected_number' },
        { title: 'Actual nr cta codes', active: false, type: 'number', db: 'cta_code_actual_number' },
        { title: 'Cashback_amount', active: false, type: 'number', db: 'cashback_amount' },
        { title: 'Confirmed_cashback', active: false, type: 'boolean', db: 'confirmed_cashback' },
        { title: 'Confirmed_cashback_at', active: false, type: 'unix', db: 'confirmed_cashback_at' },
        { title: 'Confirmed_cashback_done', active: false, type: 'boolean', db: 'confirmed_cashback_done' },
        { title: 'Confirmed_cashback_done_at', active: false, type: 'unix', db: 'confirmed_cashback_done_at' },
        { title: 'Cashback_attempt', active: false, type: 'number', db: 'cashback_attempt' },
        { title: 'Cashback_status_code', active: false, type: 'string', db: 'cashback_status_code', edit: true },
        { title: 'Cashback_status_message', active: false, type: 'number', db: 'cashback_status_message', edit: true },
        { title: 'Accepted_terms', active: false, type: 'boolean', db: 'accepted_terms' },
        { title: 'Accepted_merchandise', active: false, type: 'boolean', db: 'accepted_merchandise' },
        { title: 'Administrated', active: false, type: 'boolean', db: 'administrated', edit: true },
        { title: 'Comments', active: false, type: 'array', db: 'comments', value: 'comment' },
        { title: 'Support_email_notdone', active: false, type: 'boolean', db: 'support_email_notdone', edit: true },
        { title: 'Flagged', active: false, type: 'boolean', db: 'flagged', edit: true },
        { title: 'Status', active: false, type: 'string', db: 'status' },
        { title: 'Running_analyzer', active: false, type: 'boolean', db: 'running_analyzer' },
        { title: 'Created_at', active: false, type: 'unix', db: 'created_at' },
        { title: 'Updated_at', active: false, type: 'unix', db: 'updated_at' },
      ],
      editId: '',
      editValue: '',
      editKey: '',
      editModal: false,
      showListModal: false,
      show3rdPartyListModal: false,
      ctaDone: 'all',
      statCreatedStart: 1672578061000,
      statCreatedEnd: (new moment()).valueOf(),
      createdStart: 1672578061000,
      createdEnd: (new moment()).valueOf(),
      administrated: 'all',
      setAdministrated: false,
      showStatsModal: false,
      won: 'all',
      showCTAModal: false,
      communicationModal: false,
      newCommunicationName: '',
      receiptStore: '',
      receiptStoreTop: 20,
    };
  },
  watch: {
    search() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      if (!this.search) {
        delete query.search;
      } else {
        query.search = this.search;
      }
      this.$router.replace({ query });
    },
    columns: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.col = [];
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (!column.exclude && column.active) {
            query.col.push(column.db);
          }
        }
        this.$router.replace({ query });
        this.findAdminAllReceipts();
      },
      deep: true
    },
    statuses: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.stat = [];
        for (let i = 0; i < this.statuses.length; i++) {
          const stat = this.statuses[i];
          if (stat.active) {
            query.stat.push(stat.id);
          }
        }
        this.$router.replace({ query });
        this.resetPagination();
        this.findAdminAllReceipts();
      },
      deep: true
    },
    filters: {
      handler() {
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.filt = [];
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filter.active && !filter.func) {
            query.filt.push(filter.id);
          }
        }
        this.$router.replace({ query });
        this.resetPagination();
        this.findAdminAllReceipts();
      },
      deep: true
    },
    activationId(newVal, oldVal) {
      if (!newVal && !oldVal) {
        return;
      }
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.acti = this.activationId;
      this.$router.replace({ query });
      this.resetPagination();
      this.findAdminAllReceipts();
    },
    retailerId(newVal, oldVal) {
      if (!newVal && !oldVal) {
        return;
      }
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.reta = this.retailerId;
      this.$router.replace({ query });
      this.resetPagination();
      this.findAdminAllReceipts();
    },
    first() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.first = this.first;
      this.$router.replace({ query });
      this.findAdminAllReceipts();
    },
  },
  methods: {
    setActiveSearch() {
      const { search } = this.$route.query;
      this.search = search;
    },
    setActiveColumns() {
      const { col } = this.$route.query;
      if (col && col.length) {
        for (let i = 0; i < this.columns.length; i++) {
          const column = this.columns[i];
          if (col.includes(column.db)) {
            column.active = true;
          } else {
            column.active = false;
          }
        }
      }
    },
    setActiveStatus() {
      const { stat } = this.$route.query;
      if (stat && stat.length) {
        for (let i = 0; i < this.statuses.length; i++) {
          const status = this.statuses[i];
          if (stat.includes(status.id)) {
            status.active = true;
          } else {
            status.active = false;
          }
        }
      }
    },
    setActiveFilters() {
      const { filt } = this.$route.query;
      const { created_at_start, created_at_end } = this.$route.query;
      this.createdStart = created_at_start ? Number(created_at_start) : 1672578061000;
      this.createdEnd = created_at_end ? Number(created_at_end) : (new moment()).valueOf();
      if (filt && filt.length) {
        for (let i = 0; i < this.filters.length; i++) {
          const filter = this.filters[i];
          if (filt.includes(filter.id)) {
            filter.active = true;
          } else if (filter.id == 'datemodal') {
            filter.active = true;
          } else {
            filter.active = false;
          }
        }
      }
    },
    setPagination() {
      const { first, number } = this.$route.query;
      this.first = first ? Number(first) : 0;
      this.number = number ? Number(number) : 50;
    },
    setActivationId() {
      const { acti } = this.$route.query;
      this.activationId = acti;
    },
    setRetailerId() {
      const { reta } = this.$route.query;
      this.retailerId = reta;
    },
    getActiveFilters() {
      let realFilters = {};
      for (let i = 0; i < this.statuses.length; i++) {
        const status = this.statuses[i];
        if (status.active) {
          Object.assign(realFilters, status.filter);
        }
      }
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i];
        if (filter.active) {
          Object.assign(realFilters, filter.filter);
        }
      }
      return realFilters;
    },
    activateStatusFilter(status) {
      for (let i = 0; i < this.statuses.length; i++) {
        this.statuses[i].active = false;
      }
      if (this.statusId != status.id) {
        status.active = true;
        this.statusId = status.id;
      } else {
        status.active = false;
        this.statusId = '';
      }
    },
    activateActivationFilter(activation) {
      for (let i = 0; i < this.activationPicker.length; i++) {
        this.activationPicker[i].active = false;
      }
      if (this.activationId != activation._id) {
        activation.active = true;
        this.activationId = activation._id;
      } else {
        activation.active = false;
        this.activationId = '';
      }
    },
    activateRetailerFilter(retailer) {
      for (let i = 0; i < this.retailerPicker.length; i++) {
        this.retailerPicker[i].active = false;
      }
      if (this.retailerId != retailer._id) {
        retailer.active = true;
        this.retailerId = retailer._id;
      } else {
        retailer.active = false;
        this.retailerId = '';
      }
    },
    getValue(receipt, column) {
      if (column.type == 'array') {
        let result = [];
        for (let i = 0; i < receipt[column.db].length; i++) {
          const element = receipt[column.db][i];
          result.push(element[column.value])
        }
        return result.join(' && ');
      }
      const lastValue = this.getLastValue(receipt, column.db);
      if (column.type == 'boolean') {
        return lastValue == 'undefined' ? false : lastValue == 'true';
      } else if (column.type == 'number-floor') {
        return Math.floor(Number(lastValue)*1000)/1000;
      } else if (column.type == 'unix') {
        return moment.unix(lastValue).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return lastValue == 'undefined' ? '' : lastValue;
      }
    },
    getLastValue(receipt, db) {
      const dbSeveralKeys = db.split('+');
      let lastValue = '';
      for (let j = 0; j < dbSeveralKeys.length; j++) {
        const dbSubKey = dbSeveralKeys[j];
        const dbKeys = dbSubKey.split('.');
        try {
          let result = receipt[dbKeys[0]];
          if (dbKeys.length > 1) {
            for (let i = 1; i < dbKeys.length; i++) {
              result = result[dbKeys[i]];
            }
          }
          if (typeof result == 'object') {
            result = JSON.stringify(result);
          }
          lastValue += `${result}${db.includes('+') ? ' ': ''}`;
        } catch (error) {
          console.error('getLastValue', error, receipt, db, dbSubKey, dbKeys);
        }
      }
      return lastValue || '';
    },
    openEditModal(receipt, db) {
      this.editId = receipt._id;
      this.editValue = receipt[db];
      this.editKey = db;
      this.editModal = true;
    },
    updateReceipt() {
      this.loadedReceipts = false;
      this.$store.dispatch(`pvActivations/update${this.userType}ReceiptByKey`, {
        receiptId: this.editId,
        key: this.editKey,
        value: this.editValue,
      }).then(() => {
        this.editModal = false;
        this.editId = '';
        this.editValue = '';
        this.editKey = '';
        this.loadedReceipts = true;
      });
    },
    resetPagination() {
      console.log('resetPagination');
      this.first = 0;
      this.number = 50;
    },
    paginate(index) {
      this.first = this.first + (this.number*index);
      this.findAdminAllReceipts();
    },
    applyDateFilter() {
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.created_at_start = this.createdStart;
      query.created_at_end = this.createdEnd;
      this.$router.replace({ query });
      this.findAdminAllReceipts();
    },
    // TODO: add the search in the lists below
    getList() {
      axios.get(`/api/pv/${this.userApi}/activations/${this.activationId}/receipts/csv`
        +`?createdStart=${this.statCreatedStart/1000}&createdEnd=${this.statCreatedEnd/1000}&setAdministrated=${this.setAdministrated}&administrated=${this.administrated}&ctaDone=${this.ctaDone}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.receipts[0]?.activation.name || this.activationId}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    get3rdPartyList() {
      axios.get(`/api/pv/${this.userApi}/activations/${this.activationId}/receipts/3rdparty/csv`
        +`?setAdministrated=cta_done&administrated=false&ctaDone=true`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `thirdparty_${this.receipts[0]?.activation.name || this.activationId}_receipts_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getStats() {
      axios.get(`/api/pv/${this.userApi}/activations/${this.activationId}/statistics/csv?store=${this.receiptStore}&top=${this.receiptStoreTop}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.receipts[0]?.activation.name || this.activationId}_statistics_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    getUnclaimed() {
      axios.get(`/api/pv/${this.userApi}/activations/${this.activationId}/limited-ctas/csv?won=${this.won}`)
      .then((response) => {
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `pv_${this.receipts[0]?.activation.name || this.activationId}_unclaimed_${(new Date).toISOString()}.csv`;
        anchor.click();
      });
    },
    async createCommunication() {
      const response = await axios.post(`/api/pv/${this.userApi}/activations/${this.activationId}/communications`, {
        name: this.newCommunicationName,
        search: this.search,
        filters: this.getActiveFilters(),
        activationId: this.activationId,
        retailerId: this.retailerId,
        createdStart: this.createdStart/1000,
        createdEnd: this.createdEnd/1000 + 3600*24,
      });
      const { success } = response.data;
      if (success) {
        this.setAlert('Communication created successfully');
      } else {
        this.setAlert('Communication creation failed');
      }
      this.communicationModal = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.md-table-row {
  .md-table-cell {
    border: 1px solid #ebebeb;
  }
}
.btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  .btn-small {
    height: 20px;
    font-size: 10px;
    padding: 1px;
  }
}
.pagination {
  background-color: rgba(255, 255, 255, .5);
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 10px 20px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./ReceiptList.scss";
</style>
