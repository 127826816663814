<template>
  <div class="form" :style="cssProps">
    <div class="form-question"
      v-for="(question, index) in questions"
      :key="question.label">
      <label class="form-label"
        :for="question.label">
        {{ index + 1 }}. {{ question.label }}{{ question.required ? ' *' : '' }}
      </label>

      <div class="form-radio-wrapper"
        v-if="question.options && question.options.length && question.radio">
        <md-radio class="md-primary"
          style="font-weight: 300;"
          v-for="option in question.options"
          :key="option"
          :value="option"
          :name="getModel(question)"
          v-model="formAnswers[getModel(question)]">{{ option }}</md-radio>
        <!--<md-radio v-if="question.other" key="other" :value="formOther[getModel(question)]" :id="`${getModel(question)}other`" :name="getModel(question)"
          @change="setRadio(question, 'other')" class="md-primary">
          <InputField
            :label="transl.Offer.other" :outlined="false" :filled="true"
            @input="setRadio(question, 'other', true)"
            v-model="formOther[getModel(question)]"/>
        </md-radio>-->
      </div>

      <div class="form-checkbox-wrapper"
        v-else-if="question.options && question.options.length && question.checkbox">
        <div
          v-for="option in question.options"
          :key="option"
          class="form-checkbox-regular">
          <input type="checkbox" class="form-checkbox" :value="option" :id="getModel(question)"
            @change="setCheckbox(question, option)" />
          <span style="margin: 0 16px;">{{ option }}</span>
        </div>
        <div
          v-if="question.other"
          class="form-checkbox-regular form-checkbox-other">
          <input
            type="checkbox" class="form-checkbox" :id="`${getModel(question)}other`"
            @change="setCheckbox(question, 'other', formOther[getModel(question)])"/>
          <InputField
            :label="transl.Offer.other" :outlined="false" :filled="true"
            @input="setCheckbox(question, 'other', $event, true)"
            style="margin-left: 16px;"
            v-model="formOther[getModel(question)]"/>
        </div>
      </div>

      <!-- question.options && question.options.length && question.slider -->
      <div v-else-if="question.slider"
        style="padding: 1rem 0 3.5rem 0;">
        <img v-if="question.slider_options.media && question.slider_options.media.length"
          :src="question.slider_options.media[question.options.indexOf(formAnswers[getModel(question)] || question.options[0])].url"
          style="padding-bottom: 1rem" />
        <VueSlider
          v-else-if="question.slider_options?.options"
          v-model="formAnswers[getModel(question)]"
          v-bind="question.slider_options.options">
        </VueSlider>
        <VueSlider
          v-else-if="question?.options"
          v-model="formAnswers[getModel(question)]"
          :data="question.options">
        </VueSlider>
        <h2
          :style="`height: 49px; text-align: center; padding-top: 10px;`"
          v-if="question.slider_options.show_value">{{ formAnswers[getModel(question)] }}</h2>
      </div>

      <md-field v-else-if="question.options && question.options.length">
        <md-select v-model="formAnswers[getModel(question)]" :name="question.label"
          :id="getModel(question)">
          <md-option v-for="option in question.options" :key="option" :value="option">{{ option }}
          </md-option>
        </md-select>
      </md-field>

      <span v-else-if="question.checkbox" class="form-checkbox-wrapper"> <input
        type="checkbox"
        :id="getModel(question)" v-model="formAnswers[getModel(question)]" />{{ question.label }}
      </span>

      <md-datepicker v-else-if="question.date" v-model="formAnswers[getModel(question)]">
      </md-datepicker>

      <Uploader v-else-if="question.upload"
        role="UserSurvey"
        mediaType="Picture"
        :entity="entityType"
        :media="surveyFile"
        :entityId="entityId"
        :keepFeFile="true"
        :aspectHeight="NaN"
        :aspectWidth="NaN"
        @pickedFile="setMedia(question)">
      </Uploader>

      <InputField
        v-else-if="question.textarea"
        label=""
        inputType="textarea"
        placeholder=""
        :id="question.id"
        v-model="formAnswers[getModel(question)]"/>

      <InputField
        v-else-if="question.rating"
        inputType="rating"
        :id="question.id"
        v-model="formAnswers[getModel(question)]"/>

      <div v-else class="form-regular-input">
        <!--:class="{ 'incorrect-input' : formAnswers[getModel(question)] !== '' && question.validator && !validator[question.validator](formAnswers[getModel(question)]) }"-->
        <InputField
          v-if="!question.checkbox || !question.radio || !question.slider || !question.date || !question.textarea"
          label=""
          :outlined="true"
          :filled="false"
          :id="question.id"
          v-model="formAnswers[getModel(question)]" />
        <span class="md-helper-text the-issue">{{ question.helper }}</span>
      </div>
    </div>

    <div
      v-if="submitButton != ''"
      class="btn-medium btn-absolute btn-submit"
      @click="submit()">
      {{ submitButton }}
    </div>
  </div>
</template>

<script>
import Uploader from '../Uploader/Uploader';
import VueSlider from 'vue-slider-component';
import InputField from '../InputField/InputField';
import validator from 'validator';
import { mapGetters } from 'vuex';

export default {
  name: 'Form',
  components: {
    Uploader,
    VueSlider,
    InputField,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => {},
    },
    entityType: {
      type: String,
      required: false,
      default: '',
    },
    entityId: {
      type: String,
      required: false,
      default: '',
    },
    accentColor: {
      type: String,
      required: false,
      default: '#EA7801'
    },
    textColor: {
      type: String,
      required: false,
      default: 'black'
    },
    submitButton: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['surveyUpload', 'surveyFile']),
    cssProps() {
      return {
        '--form-accent-color': this.accentColor,
        '--form-text-color': this.textColor,
      }
    }
  },
  data() {
    return {
      validator,
      formAnswers: {},
      formOther: {},
      answeredForm: false,
    }
  },
  mounted() {
    // console.log('answers', this.answers);
    if (Object.keys(this.answers)) {
      this.formAnswers = this.answers;
    }
  },
  watch: {
    formAnswers: {
      handler() {
        /*let validated = true;
        for (let j = 0; j < this.questions.length; j++) {
          const question = this.questions[j];
          console.log(this.getModel(question), this.formAnswers[this.getModel(question)]);
          if (question.required && !this.formAnswers[this.getModel(question)]) {
            validated = false;
            break;
          }
        }*/
        this.sendSurveyAnswers();
      },
      deep: true
    }
  },
  methods: {
    sendSurveyAnswers() {
      const { success } = this.checkRequiredQuestions();
      if (success) {
        this.answeredForm = true;
        this.$emit('answeredAll', this.formAnswers);
      } else {
        this.answeredForm = false;
        this.$emit('missingRequired')
      }
    },
    checkRequiredQuestions() {
      let unfilled = false;
      let message = '';
      for (let i = 0; i < this.questions.length; i++) {
        let question = this.questions[i];
        if (question.required) {
          let answeredRequiredField = false;
          if (question.upload) {
            if (this.surveyUpload && this.surveyUpload.data) {
              answeredRequiredField = true;
            }
          } else {
            const objectKeys = Object.keys(this.formAnswers);
            for (let j = 0; j < objectKeys.length; j++) {
              const objectKey = objectKeys[j];
              if (objectKey === this.getModel(question) && this.formAnswers[objectKey]) {
                answeredRequiredField = true;
                break;
              }
            }
          }
          if (!answeredRequiredField) {
            message += `${i+1}, `;
            unfilled = true;
          }
        }
      }
      if (unfilled) {
        message.slice(message.length - 2);
        message = this.transl.Offer.requiredquestion.interpolate({ message });
        return { success: false, message };
      } else {
        return { success: true };
      }
    },
    setCheckbox(question, option, value, notCheck) {
      if (question.checkbox && question.options && question.options.length) {
        if (
          !this.formAnswers[this.getModel(question)] ||
          !this.formAnswers[this.getModel(question)].length
        ) {
          this.formAnswers[this.getModel(question)] = [];
        }
        const isOther = option == 'other';
        let isChecked = -1;
        for (let i = 0; i < this.formAnswers[this.getModel(question)].length; i++) {
          const internalOption = this.formAnswers[this.getModel(question)][i];
          if (option === internalOption) {
            isChecked = i;
          } else if (isOther && this.formOther[this.getModel(question)] == internalOption) {
            isChecked = i;
          }
        }
        if (notCheck) {
          isChecked = -1;
          this.removeOldOther(question, value);
        }
        if (isChecked < 0) {
          if (isOther) {
            const otherOptionCheckbox = document.getElementById(`${this.getModel(question)}other`);
            otherOptionCheckbox.checked = true;
            this.formAnswers[this.getModel(question)].push(this.formOther[this.getModel(question)]);
          } else {
            this.formAnswers[this.getModel(question)].push(option);
          }
        } else {
          this.formAnswers[this.getModel(question)].splice(isChecked, 1);
        }
        // Manually add this here, because the watcher doesn't trigger on array changes
        this.sendSurveyAnswers();
      }
    },
    setRadio(question, option, notCheck) {
      const isOther = option == 'other';
      if (isOther) {
        const otherOptionRadio = document.getElementById(`${this.getModel(question)}other`);
        if (notCheck) {
          otherOptionRadio.checked = true;
        } else {
          otherOptionRadio.checked = !otherOptionRadio.checked;
        }
        if (otherOptionRadio.checked) {
          this.formAnswers[this.getModel(question)] = this.formOther[this.getModel(question)];
        }
      }
    },
    removeOldOther(question, newValue) {
      for (let i = 0; i < this.formAnswers[this.getModel(question)].length; i++) {
        const internalOption = this.formAnswers[this.getModel(question)][i];
        let remove = true;
        for (let j = 0; j < question.options.length; j++) {
          const option = question.options[j];
          if (option == internalOption && internalOption != newValue) {
            remove = false;
          }
        }
        if (remove) {
          this.formAnswers[this.getModel(question)].splice(i, 1);
        }
      }
    },
    submit() {
      const { success, message } = this.checkRequiredQuestions();
      if (!success) {
        this.setAlert(message);
      } else {
        this.$emit('submit', this.formAnswers);
      }
    },
    getModel(question) {
      return question.model ? question.model : 'none';
    },
    setMedia(question) {
      this.$set(this.formAnswers, this.getModel(question), 'there is a file');
    }
  }
}

</script>

<style lang="scss">
// Defaults to brand-yellow, or sets to component accent color if there is one
$themeColor: var(--form-accent-color);
$themeColor: $brand-yellow !default;

$bgColor: #e0e0e0 !default;
$disabledColor: #666 !default;

$dotShadowColor: var(--form-accent-color);
$dotShadowColor: rgba($themeColor, 0.38) !default;

$railBorderRadius: 15px !default;
$railColorDisabled: #ccc !default;
$dotBorderRadius: 50% !default;
$tooltipFontSize: 12px !default;
$tooltipSize: 30px !default;
$tooltipColor: #fff !default;
$stepBorderRadius: 50% !default;
$labelFontSize: 14px !default;

.form {
  /* component style */
  .vue-slider-disabled {
    .vue-slider-rail {
      background-color: $railColorDisabled;
    }

    .vue-slider-dot-handle {
      background-color: $disabledColor;
    }

    .vue-slider-process {
      background-color: $disabledColor;
    }

    .vue-slider-mark-step {
      background-color: $disabledColor;

      &-active {
        background-color: $railColorDisabled;
      }
    }
  }

  /* rail style */
  .vue-slider-rail {
    background-color: $bgColor;
    border-radius: $railBorderRadius;
    width: 90%;
    margin: auto;
  }

  /* process style */
  .vue-slider-process {
    background-color: $themeColor !important; // remove !important when we use Form everywhere
    border-radius: $railBorderRadius;
  }

  .vue-slider-dot-handle-focus{
    box-shadow: 0.5px 0.5px 2px 1px rgba($themeColor, 32%) !important;
  }

  /* mark style */
  .vue-slider-mark {
    z-index: 4;
    font-family: var(--medium-font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #111111;

    @at-root &-step {
      width: 100%;
      height: 100%;
      border-radius: $stepBorderRadius;
      background-color: $themeColor;

      &-active {
        background-color: $bgColor;
      }
    }

    @at-root &-label {
      font-size: $labelFontSize;
      white-space: nowrap;

      &-active {
        background-color: $themeColor;
      }
    }
  }

  /* dot style */
  /*.vue-slider-dot {
    @at-root &-handle {
      cursor: pointer;
      position: relative;
      width: 150%;
      height: 150%;
      border-radius: $dotBorderRadius;
      background-color: $themeColor;
      box-sizing: border-box;
      bottom: 4px;
      box-shadow: 0.5px 0.5px 2px 1px $dotShadowColor;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 200%;
        height: 200%;
        border-radius: $dotBorderRadius;
        transform: translate(-50%, -50%) scale(0);
        z-index: -1;
        transition: transform 0.2s;
        background: $themeColor;
        opacity: .6;
        box-shadow: 0.5px 0.5px 2px 1px $dotShadowColor;
      }

      @at-root &-focus{
        box-shadow: 0.5px 0.5px 2px 1px $dotShadowColor;
        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }

      @at-root &-disabled {
        cursor: not-allowed;
        background-color: $disabledColor !important;
      }
    }

    @at-root &-tooltip {
      visibility: visible;

      @at-root &-show {
        .vue-slider-dot-tooltip-inner {
          opacity: 1;

          &-top {
            transform: rotateZ(-45deg);
          }

          &-bottom {
            transform: rotateZ(135deg);
          }

          &-left {
            transform: rotateZ(-135deg);
          }

          &-right {
            transform: rotateZ(45deg);
          }
        }
      }

      @at-root &-inner {
        border-radius: 50% 50% 50% 0px;
        background-color: $themeColor;
        opacity: 0;
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.2s linear;

        &-top {
          transform: translate(0, 50%) scale(0.01) rotate(-45deg);
        }

        &-bottom {
          transform: translate(0, -50%) scale(0.01) rotateZ(135deg);
        }

        &-left {
          transform: translate(50%, 0) scale(0.01) rotateZ(-135deg);
        }

        &-right {
          transform: translate(-50%, 0) scale(0.01) rotateZ(45deg);
        }

        @at-root .vue-slider-dot-tooltip-text {
          font-size: $tooltipFontSize;
          white-space: nowrap;
          text-align: center;
          color: $tooltipColor;
          width: $tooltipSize;
          height: $tooltipSize;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: content-box;
        }

        &-top .vue-slider-dot-tooltip-text {
          transform: rotateZ(45deg);
        }

        &-bottom .vue-slider-dot-tooltip-text {
          transform: rotateZ(-135deg);
        }

        &-left .vue-slider-dot-tooltip-text {
          transform: rotateZ(135deg);
        }

        &-right .vue-slider-dot-tooltip-text {
          transform: rotateZ(-45deg);
        }
      }
    }
  }*/

  // VueperSlide

  .vueperslide {
    color: transparent;
  }
}

</style>

<style lang="scss" scoped>
@import "./Form.scss"
</style>
