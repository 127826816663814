import axios from 'axios';
import { encodeObject } from './utils';

export default {
  findAdminUsers: (payload) => {
    return axios.put('/api/admin/users', payload)
      .then(response => ({
        users: response.data.users != null
          ? response.data.users : [],
      }));
  },

  getAdminUser: (payload) => {
    return axios.get(`/api/admin/users/${payload.entityId}`)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : [],
      }));
  },

  adminDeleteUser: (userId) => {
    return axios.delete(`/api/admin/users/${userId}`)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  deleteLostUsers: () => {
    return axios.delete(`/api/admin/users`)
      .then(response => ({
        deleted: response.data.deleted != null
          ? response.data.deleted : {},
      }));
  },

  saveAdminPartnerToken: (partnerName) => {
    return axios.post(`/api/admin/partners/${partnerName}/token`, {})
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  testWS: () => {
    return axios.post(`/api/admin/ws/test`)
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  executeEligbleActions: () => {
    return axios.post('/api/pv/admin/activations/execute-eligble-actions')
      .then(response => ({
        message: response.data.message != null
          ? response.data.message : '',
      }));
  },

  adminDeleteUserOffer: (payload) => {
    return axios.put(`/api/admin/users/${payload.userId}/offers`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  adminAddUserRole: (payload) => {
    return axios.put(`/api/admin/users/${payload.userId}/roles`, payload)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  adminRemoveUserRole: (userId, role) => {
    return axios.delete(`/api/admin/users/${userId}/roles?role=${encodeObject(role)}`)
      .then(response => ({
        user: response.data.user != null
          ? response.data.user : {},
      }));
  },

  deleteAdminRemovedUsers: () => {
    return axios.delete('/api/admin/users')
      .then(response => ({
        deleted: response.data.deleted != null
          ? response.data.deleted : {},
      }));
  },
};
